<template>
    <div id="tagsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="reports"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="reporting_manageReport"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper" v-if="!isEmptyList">
                                    <router-link v-if="!isEmptyList" :to="{ name: 'generateReport' }" id="reporting_createNewReportButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="la la-paper-plane kt-margin-r-5" style="font-size: 1.4rem !important;"></i>
                                        {{ $t("reporting_sendAllReports") }}
                                    </router-link>
                                    <span class="kt-margin-l-10">{{ $t("common_or") }}</span>
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newReport' }" id="reporting_createNewReportButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10" data-cy="createNewReportingBtn">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("reporting_createReport") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                                                            <path d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z" fill="#000000"/>
                                                            <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("reporting_reportGenerationList") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="REPORT" mainTextKey="reporting_notFound" subTextKey="reporting_notFoundSubLabel" imgName="empty_report.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="reports_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>
                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteReportsTable: null,
            isEmptyList: false,
            isBatteryStatusHelpDisplayed: false
        };
    },

    created: function() {
        console.log("Component(Reports)::created() - called");
        if (this.createAnotherReport) {
            this.$router.push({ name: "newReport" });
        } else {
            this.getAllReportOnSite(this.siteId);
        }
    },

    mounted: function() {
        console.log("Component(Reports)::mounted() - Init metronic layout");
        KTLayout.init();
    },

    beforeDestroy: function() {
        console.log("Component(Reports)::beforeDestroy() - called");
        // Reset store
        if (this.siteReportsTable) {
            this.siteReportsTable.destroy();
            this.siteReportsTable = null;
        }

        this.resetReportState();
    },

    destroyed: function() {
        console.log("Component(Reports)::destroyed() - called");
    },

    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },

    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteReports: function(reports) {
            console.log("Component(siteReports)::watch(siteReports) called with : ", reports);
            if(reports) {
                this.initKtDataTable();
            }
        },
        user: function(user) {
            if (user) {
                console.log("Component(siteReports)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        }
    },

    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteReports", "currentSite", "createAnotherReport", "user"])
    },

    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "showReportModal", "getAllReportOnSite", "resetReportState", "updateReportState", "generateReport"]),
        onEditReport: function(reportId) {
            this.$router.push({ name: "editReport", params: { reportId: reportId, siteId: this.siteId } });
        },
        onShowMoreReport: function(reportId) {
            if (this.siteReports) {
                const currentReport = this.siteReports.find (report => report.id === reportId);
                this.showReportModal({report: currentReport });
            }
        },

        onSendReport: function(reportId) {
            if (this.siteReports) {
                const currentReport = this.siteReports.find (report => report.id === reportId);
                const payload = {
                    arrayOfEmails: currentReport.emails,
                    from: currentReport.from,
                    reportFormat: currentReport.fileType,
                    reportType: currentReport.reportTemplate,
                    siteId: this.siteId,
                    timezone: currentReport.timezone,
                    to: currentReport.to
                }

                this.generateReport(payload);
            }
        },

        registerEventsOnSiteReportsTable() {
            var self = this;
            $("#reports_datatable").on("kt-datatable--on-layout-updated", () => {

                $(".onChangeStateRadio").off().on("change", function() {
                    const reportId = $(this).attr("data-reportid");
                    console.log("Component(SiteReport)::registerEventsOnSiteAssetsTable(onChangeStateRadio). reportId = " + reportId);
                    self.updateReportState({
                        reportId: reportId,
                        siteId: self.siteId,
                        isActive: this.checked
                    });
                });

                $(".sendReportButton").off().on("click", function() {
                    const reportId = $(this).attr("data-reportid");
                    console.log("Component(SiteReport)::registerEventsOnSiteAssetsTable(sendReportButton). reportId = " + reportId);
                    self.onSendReport(reportId);
                })
                
                $(".showMoreButton").off().on("click", function() {
                    const reportId = $(this).attr("data-reportid");
                    console.log("Component(SiteReport)::registerEventsOnSitesTable(showMoreButton) clicked. reportId = " + reportId);
                    self.onShowMoreReport(reportId);
                });

                $(".deleteReportButton")
                    .off()
                    .on("click", function() {
                        const nameReport = $(this).attr("data-name");
                        const reportId = $(this).attr("data-reportid");
                        console.log("Component(SiteReport)::registerEventsOnSitesTable(deleteReportButton) clicked. reportId = " + reportId);
                        self.showDeleteModal({
                            textContent: i18n.t("reporting_deleteReportConfirmText", { nameReport: nameReport }),
                            actionName: "DELETE_REPORT",
                            params: { siteId: self.siteId, reportId: reportId } }
                        );
                    });

                $(".editReportButton")
                    .off()
                    .on("click", function() {
                        let reportId = $(this).attr("data-reportid");
                        console.log("Component(SiteReport)::registerEventsOnSitesTable(editReportButton) clicked. reportId = " + reportId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue of asset
                        self.onEditReport(reportId);
                    });
                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");
                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },

        initKtDataTable() {

            let data = [];
            if (this.siteReports && this.siteReports.length > 0) {
                const reports = _.orderBy(this.siteReports, ['createdAt'], ['desc']);
                data = _.map(reports, report => {
                    let finalReport = {...report};
                    if (finalReport.reportTemplate) {
                        finalReport.reportTemplate = commonVueHelper.getPropFromReport("template", finalReport);
                    }
                    if (finalReport.reportType) {
                        finalReport.type = commonVueHelper.getPropFromReport("reportType", finalReport);
                    }
                    if (finalReport.scheduler) {
                        finalReport.occurrence = commonVueHelper.getPropFromReport("scheduler", finalReport);
                    }
                    finalReport.recipients = finalReport.emails.length;
                    if (finalReport.hasOwnProperty('isActive')) {
                        if (finalReport.isActive) {
                            finalReport.status = i18n.t("reporting_reportStatusActive");
                        } else {
                            finalReport.status = i18n.t("reporting_reportStatusInactive");
                        }
                    }
                    return finalReport;
                })
            }

            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(data),
                    pageSize: 20,
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("common_name"),
                        default: "-",
                        textAlign: "center",
                        overflow: "visible",
                        autoHide: false,
                        template: function(row) {
                            if (row.name) {
                                var createdAt = moment(row.createdAt);
                                var now = moment();
                                var duration = moment.duration(now.diff(createdAt));
                                var minutes = duration.asMinutes();
                                if (minutes < 15) {
                                    return '\
                                        <div class="kt-user-card-v2 word-break">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="editTagButton kt-link kt-user-card-v2__name editReportButton" data-reportid="' + row.id + '" href="javascript:;">' + row.name + "<span class='labelNew'>" + i18n.t("common_new") + "</span></a>\
                                            </div>\
                                        </div>\
                                    ";
                                } else {
                                    return '\
                                        <div class="kt-user-card-v2 word-break">\
                                            <div class="kt-user-card-v2__details openResourceLink">\
                                                <a class="editTagButton kt-link kt-user-card-v2__name editReportButton" data-reportid="' + row.id + '" href="javascript:;">' + row.name + "</a>\
                                            </div>\
                                        </div>\
                                    ";
                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "reportTemplate",
                        title: i18n.t("reporting_templateReport"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.reportTemplate) {
                                return (
                                    `<div class="kt-user-card-v2__details" style="margin-left:8px;"> ${row.reportTemplate} </div>`
                                );
                            }
                        }
                    },
                    {
                        field: "type",
                        title: i18n.t("common_type"),
                        textAlign: "center",
                        template: function(row) {
                            if(row.type) {
                                return row.type;
                            }
                            return "-";
                        }
                    },
                    {
                        field: "occurrence",
                        title: i18n.t("reporting_occurrenceReport"),
                        textAlign: "center",
                        template: function(row) {
                            if (row.occurrence) {
                                return `<div class="kt-user-card-v2__details"> ${row.occurrence} </div>`;
                            } 
                            return "-";
                        }
                    },
                    {
                        field: "recipients",
                        title: i18n.t("reporting_recipientsReport"),
                        textAlign: "center",
                        overflow: "visible",
                        template: function(row) {
                            if (row.recipients) {
                                return `<div class="kt-user-card-v2__details"> ${row.recipients} </div>`;
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "status",
                        title: i18n.t("reporting_statusReport"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false,
                        sortable: false,
                        template: function(row) {
                            if (row.hasOwnProperty('isActive') && row.reportType.toUpperCase() !== "ON_DEMAND") {
                                if (row.isActive) {
                                    return (
                                        '<div style="margin-top:10px;"><span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                        row.status +
                                        "</span>"+
                                        '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                            '<label>'+
                                                '<input class="onChangeStateRadio" checked="checked" data-reportid="'+ row.id +'" type="checkbox">'+
                                                '<span></span>'+
                                            '</label>'+
                                        '</span></div>'
                                    );
                                } else {
                                    return (
                                        '<div style="margin-top:10px;"><span class="kt-badge kt-badge--danger kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-danger">' +
                                        row.status +
                                        "</span>"+
                                        '<span class="kt-switch kt-switch--sm kt-switch--outline kt-switch--icon kt-switch--success" style=" display: block; ">'+
                                            '<label>'+
                                                '<input class="onChangeStateRadio" type="checkbox" data-reportid="'+row.id+'">'+
                                                '<span></span>'+
                                            '</label>'+
                                        '</span></div>'
                                    );
                                }
                            } else {
                                return "-";
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 145,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            return (
                                `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("reporting_sendReport") +
                                `" type="button" data-reportid = "` +
                                row.id +
                                `" class="btn btn-outline-brand btn-sm btn-icon sendReportButton">
                                        <i class="la la-paper-plane"></i>    
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("reporting_showMore") +
                                `" type="button" data-reportid = "` +
                                row.id +
                                `" class="btn btn-outline-info btn-sm btn-icon showMoreButton">
                                        <i class="la la-info"></i>    
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_edit") +
                                `" type="button" data-reportid="` +
                                row.id +
                                `" class="btn btn-outline-brand btn-sm btn-icon editReportButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                i18n.t("common_delete") +
                                `" type="button" data-reportid="` +
                                row.id +
                                `" data-name= "` +
                                row.name +
                                `" class="btn btn-outline-danger btn-sm btn-icon deleteReportButton">
                                        <i class="la la-trash"></i>
                                    </button>
                                `
                            );
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.siteReportsTable) {
                $("#reports_datatable").KTDatatable().destroy();
                // After destroy set to null
                this.siteReportsTable = null;
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.siteReportsTable = $("#reports_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteReportsTable();
                this.isBatteryStatusHelpDisplayed = true;
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.isBatteryStatusHelpDisplayed = false;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
